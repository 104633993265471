<template>
  <div
    class="modal fade"
    id="modal_create_record"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-900px">
      <div class="modal-content">
        <div class="modal-header">
          <h2>Create a record</h2>

          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            @click="resetForm()"
          >
            <span class="svg-icon svg-icon-1">
              <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
            </span>
          </div>
        </div>

        <div class="modal-body py-lg-10 px-lg-10">
          <el-form
            id="create_record_form"
            :model="recordData"
            :rules="rules"
            ref="formRef"
            class="form"
          >
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span class="required">Transaction Type</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="record_type">
                  <el-select
                      class="form-select-solid"
                      v-model="recordData.record_type"
                      @change="resetPaymentType()">
                      <el-option v-for="(type, index) in transactionTypes" :key="index" :label="type" :value="type">
                          {{type}}
                      </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span class="required">Date</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="record_date">
                  <el-date-picker
                    v-model="recordData.record_date"
                    type="date"
                    start-placeholder="transaction date"
                    class="w-100"
                    format="MM/DD/YYYY">
                  </el-date-picker>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span class="required">Amount</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="amount">
                  <input
                    v-model="recordData.amount"
                    min="0.01" 
                    step="0.01"
                    type="number"
                    class="form-control form-control-lg form-control-solid"
                  />
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span class="required">Payment Method</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="payment_method">
                  <el-select
                    class="form-select-solid"
                    v-model="recordData.payment_method">
                    <el-option v-for="(type, index) in paymentMethodTypes" :key="index" :label="type" :value="type">
                        {{type}}
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span class="required">Category</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="payment_type">
                  <el-select
                    class="form-select-solid"
                    v-model="recordData.payment_type">
                    <el-option v-for="(type,index) in filteredPaymentTypes" :key="index" :label="type" :value="type">{{type}}</el-option>
                  </el-select>
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span>Student</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="student">
                  <Multiselect v-model="selectedStudents" v-bind="studentMultiselect"></Multiselect>
                  <!--<el-autocomplete
                    class="form-select-solid"
                    v-model="selectedStudent"
                    :fetch-suggestions="getStudent"
                    :clearable="true"
                    placeholder="Enter student name or id"
                    @select="selectStudent">
                    <template v-slot="scope">
                      {{ scope.item.first_name +" "+ scope.item.last_name +"("+scope.item.student_id+")"}}
                  </template>
                  </el-autocomplete>-->
                </el-form-item>
              </div>
            </div>
            <div class="row mb-6">
              <label
                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-4"
              >
                <span>Memo</span>
              </label>
              <div class="col-lg-8">
                <el-form-item prop="memo">
                <input
                    v-model="recordData.memo"
                    style="width:100%;"
                    class="form-control form-control-lg form-control-solid"
                />
                </el-form-item>
              </div>
            </div>
            <div class="text-center">
              <button
                id="close_finance_record_btn"
                class="btn btn-white me-3"
                data-bs-dismiss="modal"
                @click="resetForm()"
              >
                Cancel
              </button>

              <el-button type="primary" @click="submit()" :loading="loading">
                {{ loading ? "Please wait..." : "Submit" }}
              </el-button>
            </div>
            </el-form>
          </div>
        </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Create App-->
</template>

<style lang="scss" scoped>
.el-input--suffix .el-input__inner {
  background-color: #f5f8fa;
}

.el-input__inner {
  background-color: #f5f8fa;
}
</style>

<script lang="ts">
import { computed, defineComponent, onBeforeMount, ref, toRefs } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "create-record-modal",
  components: {
    Multiselect
  },
  props:{
    transactionTypes:[],
    paymentMethodTypes:[],
    paymentTypes: [] as any,
    students:[] as any
  },
  emits :['getFinanceRecords'],
  setup(props,{emit}) {
    const store = useStore();
    const { students, paymentTypes } = toRefs(props);
    const formRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const user = store.getters.currentUser;
    const selectedStudents = ref([] as any);

    const recordData = ref
    ({
      record_type :'',
      record_date  :'',
      students  : undefined,
      amount  : 0,
      payment_method  :'',
      payment_type  :'',
      memo  :'',
      user_id: user.id
    });

    const resetForm = () =>{
      recordData.value.record_type ='';
      recordData.value.record_date ='';
      recordData.value.students  =undefined;
      recordData.value.amount = 0;
      recordData.value.payment_method ='';
      recordData.value.payment_type ='';
      recordData.value.memo ='';
      selectedStudents.value = [];
    }

    const getStudent=(queryString, callback)=>{
        let selectedList = students.value.filter(data => (!queryString 
                            || (data.first_name +" "+ data.last_name +"("+data.student_id+")").toLowerCase().includes(queryString.toLowerCase())));
        callback(selectedList);
    }

    const resetPaymentType = () =>{
        recordData.value.payment_type = '';
    }

    const filteredPaymentTypes = computed(() =>{
        var currentPaymentTypes = [];

        if(recordData.value.record_type){
            currentPaymentTypes = paymentTypes.value[recordData.value.record_type];
        }

        return currentPaymentTypes;
    })
    
    const rules = ref({
      record_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "blur",
        },
      ],
      record_date: [
        {
          required: true,
          message: "Please select date",
          trigger: "change",
        },
      ],
      amount: [
        {
          required: true,
          message: "Please enter valid amount",
          trigger: "change",
        },
      ],
      payment_method: [
        {
          required: true,
          message: "Please select payment method",
          trigger: "change",
        },
      ],
      payment_type: [
        {
          required: true,
          message: "Please select transaction type",
          trigger: "change",
        },
      ],
    });

    const createFinanceRecords = (formData) : any => {
      return new Promise<void>((resolve, reject) => {
        ApiService.setHeader();
        ApiService.post("finance/records", formData)
          .then(() =>{
            resolve();
          })
          .catch(({ response }) => {
              loading.value = false;
              Swal.fire({
                text: "Failed to create the new transaction",
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
          });
      });
    }

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          recordData.value.students = selectedStudents.value; 
             
          createFinanceRecords(recordData.value).then(() => {
            Swal.fire({
              text: "Form has been successfully submitted!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              emit('getFinanceRecords');
              loading.value = false;
              let closeEditBtn = document.getElementById('close_finance_record_btn');
              closeEditBtn?.click();
            });
          });
        } else {
          Swal.fire({
            text: "Please fill out all required fields",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    const studentsData = computed(() => {
      return students.value != undefined && students.value.length > 0 ? students.value.map(x=>{return {value:x.id, label:x.first_name+" "+x.last_name+"("+x.student_id+")"}}) :[];
    });

    const studentMultiselect = ref({
      mode: "tags",
      options: studentsData,
      searchable: true,
      createTag: true
    });

    return {
      recordData,
      submit,
      rules,
      loading,
      formRef,
      getStudent,
      selectedStudents,
      filteredPaymentTypes,
      resetPaymentType,
      resetForm,
      studentMultiselect
    };
  },
});
</script>

<style scoped>
* >>> .el-form-item{
  margin-bottom: 0px;
}
</style>
