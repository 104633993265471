<template>
<div style="display:inline-block; width:100%;">
    <div align="right" style="padding-bottom:10px;">
        <div class="me-4" >
            <a
                href="#"
                class="btn btn-sm btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#modal_create_record"
                id="record_create_button"
            >
                Create new record
            </a>
            <button
                class="btn btn-sm btn-success"
                id="record_download_button"
                @click="downloadFinanceRecords"
                style="margin-left:5px;"
            >
                Download
            </button>
        </div>
    </div>
    <div align="right" style="float:right;width:100%;">
        <div style="display:inline-block;padding-right: 10px;" v-if="financeFilter">
            Date Range : {{moment(financeFilter.record_dates[0]).format('MM/DD/YYYY')}} ~ {{moment(financeFilter.record_dates[1]).format('MM/DD/YYYY')}}
        </div>
        <el-input
            v-model="search.searchValue"
            placeholder="Ref # / Type / Amount / Method / Category / Memo / Student Name"
            class="col-md-6"
            style="padding-bottom:10px; padding-right:10px;"/>
        <div class="me-4" style="display: inline-block;">
          <button
              type="button"
              class="btn btn-sm btn-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
              data-kt-menu-flip="top-end"
          >
            <span class="svg-icon svg-icon-5 svg-icon-gray-500 me-1">
              <inline-svg src="/media/icons/duotune/general/gen031.svg" />
              </span>
              Filter
          </button>
          <div
            class="menu menu-sub menu-sub-dropdown w-430px w-md-430px financial-filter"
            style="overflow-y: scroll;max-height: 68%;"
            data-kt-menu="true">
            <FinancialFilter
                v-if="financeFilter"
                v-bind:transactionTypes="transactionTypes.values"
                v-bind:paymentMethodTypes="paymentMethods.values"
                v-bind:students="students.values"
                v-bind:getFinanceRecords="getFinanceRecords">
            </FinancialFilter>
        </div>
        </div>
    </div>
</div>
  <div class="card mb-5 mb-xl-10" id="financial_list">
    <el-table 
      :data="pagedTransactions" 
      style="width: 100%; font-size:12px;"
      :default-sort = "{prop: 'date', order: 'descending'}"
      show-summary
      :summary-method="getSummaries">
      <el-table-column prop="id" label="Ref #">
          <template v-slot="scope">
              {{String(scope.row.id).padStart(8, 0)}}
          </template>
      </el-table-column>
      <el-table-column prop="transactionDate" label="Date">
          <template v-slot="scope">
              <div style="display: inline-block;" >
                  <label> {{moment(scope.row.record_date).format('MM/DD/YYYY')}} </label>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="record_type" label="Type" width="100">
          <template v-slot="scope">
            <span v-if="scope.row.record_type == 'Receivable'" class="badge-light-danger badge fs-7 fw-bolder my-2">{{scope.row.record_type}}</span>
            <span v-else-if="scope.row.record_type == 'Payable'" class="badge-light-success badge fs-7 fw-bolder my-2">{{scope.row.record_type}}</span>
            <span v-else-if="scope.row.record_type == 'Deposit'" class="badge-light-primary badge fs-7 fw-bolder my-2">{{scope.row.record_type}}</span>
            <span v-else-if="scope.row.record_type == 'Expense'" class="badge-light-warning badge fs-7 fw-bolder my-2">{{scope.row.record_type}}</span>
            <span v-else-if="scope.row.record_type == 'Credit'" class="badge-light-info badge fs-7 fw-bolder my-2">{{scope.row.record_type}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="amount" label="Amount">
          <template v-slot="scope">
              <div style="display: inline-block;text-align:right;" >
                  <label> {{scope.row.amount.toLocaleString("en-US", {style: "currency", currency: "USD"})}} </label>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="method" label="Method">
          <template v-slot="scope">
              <div style="display: inline-block;">
                  <label> {{scope.row.payment_method}} </label>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="type" label="Category" >
          <template v-slot="scope">
              <div style="display: inline-block;">
                  <label> {{scope.row.payment_type}} </label>
              </div>
          </template>
      </el-table-column>      
      <el-table-column prop="student" label="Student" >
          <template v-slot="scope">
              <div style="display: inline-block;" >
                  <label> {{scope.row.student != null ? scope.row.student.first_name + " " + scope.row.student.last_name : ""}} </label>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="memo" label="memo" width="200">
          <template v-slot="scope">
              <div style="display: inline-block;">
                  <label> {{scope.row.memo}} </label>
              </div>
          </template>
      </el-table-column>
      <el-table-column prop="createdBy" label="Created By">
          <template v-slot="scope">
            <div style="display: inline-block;" v-if="scope.row.transactionId != 0">
                <label> {{scope.row.user.name}} </label>
            </div>
          </template>
      </el-table-column>
      <el-table-column align="right" width="50">
          <template #default="scope">
              <img src="/media/icons/duotune/general/gen027.svg" class="cursor-pointer fs-2x delete_icon" title="Delete" 
                  @click="handleDelete(scope.row)"/>
          </template>
      </el-table-column>
    </el-table>
    <div align="right">
      <el-pagination 
        layout="sizes, prev, pager, next" 
        v-model:page-size="pageSize" 
        :page-sizes="pageSizes"
        :small="small"
        :disabled="disabled"
        :background="background"
        :total="getFilteredFinanceRecords().length"
        @size-change="handleSizeChange"
        @current-change="setPage">
      </el-pagination>
    </div>
  </div>
  <!--end::details View-->
  <template v-if="financeFilter">
    <CreateRecord 
        v-bind:transactionTypes="transactionTypes.values"
        v-bind:paymentMethodTypes="paymentMethods.values"
        v-bind:paymentTypes="paymentTypes.values"
        v-bind:students="students.values"
        @getFinanceRecords="getFinanceRecords"
    ></CreateRecord>
  </template>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FinancialFilter from "@/views/financial/FinancialFilter.vue";
import CreateRecord from "@/views/financial/modals/CreateRecord.vue";
import { FinanceFilter } from "@/types/FinanceModel";
import { setFinanceFilter } from "@/core/helpers/finance";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
  name: "financial",
  components: {
    FinancialFilter,
    CreateRecord
  },
  setup() {
    const store = useStore();
    const transactionTypes = [];
    const paymentMethods = [];
    const paymentTypes = [];
    const students = [];
    const route= useRoute();
    
    const getTypes = async() => {
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    transactionTypes.values = data.recordTypes;
                    paymentMethods.values = data.paymentMethods;
                    paymentTypes.values = data.paymentTypes;
                })
                .catch(({ response }) => {
                console.log('getTypes error => '+response.data.errors);
                });
    }

    const getStudents = async() =>{ 
        ApiService.setHeader();
        await ApiService.get("student/status/Active,Draft,COS,Initial,Reinstate,Exit")
                .then(({ data }) => {
                    students.values = data.data;
                })
                .catch(({ response }) => {
                console.log('getStudents error => '+response.data.errors);
                });
    }

    const financeRecords : any[] = reactive([]);

    const getFinanceRecords = async () => {
        if(financeRecords.length > 0){
            financeRecords.splice(0, financeRecords.length);
        }

        await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.post("finance/search-records", financeFilter.value)
                .then(({ data }) => {
                    Object.assign(financeRecords,data.data);
                    resolve();
                })
                .catch(({ response }) => {
                    reject(new Error ('getFinanceRecords error => '+response.data.errors));
                });
        });
    }
    
    const pageSize = ref(20);
    let page = reactive({pageValue : 1});
    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredFinanceRecords().length;
      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })
    
    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const financeFilter = computed(() => {
        return  store.getters.currentFinanceFilter.financeFilter;
    });

    const getFilteredFinanceRecords = () =>{
        return financeRecords
                .filter(data => (!search 
                            || String(data.id).padStart(8, "0").toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_method.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.amount.toString().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.record_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || (data.student?.first_name + " " + data.student?.last_name).toLowerCase().includes(search.searchValue.toLowerCase()))
                )
                .sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.record_date!) < Date.parse(b.record_date!))
                        return 1;
                    if (Date.parse(a.record_date!) > Date.parse(b.record_date!))
                        return -1;
                    if(a.id < b.id) 
                        return  1;
                    if(a.id > b.id)
                        return -1;

                    return 0;
                });
    }

    const pagedTransactions = computed(()=>{
      return getFilteredFinanceRecords()
                .slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
    })

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleDelete = (row) => {
        ElMessageBox.confirm('Are you sure to delete this record?')
        .then(() => {
            ApiService.setHeader();
            ApiService.delete("finance/record/"+row.id)
                .then(() => {
                    let index = financeRecords.findIndex(d => d.id === row.id);
                    financeRecords.splice(index,1);   
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        });
    };

    const search = reactive({searchValue : ''});

    const getSummaries = (param) => {
        const { columns, data } = param;
        const sums = Array<any>();
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'Total';
                return;
            }
            else if(index ===3) {
                var totalAmount = 0;
                if (financeRecords) {
                    getFilteredFinanceRecords()
                            .forEach(record => {
                                let value = parseFloat(record.amount);                                
                                if(record.record_type == 'Deposit' || record.record_type == 'Credit' || record.record_type == 'Payable'){
                                    totalAmount = totalAmount + value;
                                }
                                else{
                                    totalAmount = totalAmount - value;
                                }
                            });

                    sums[index] = '$'+totalAmount;
                    return;
                } 
                sums[index] = '$ '+totalAmount;
            }
            else{
                sums[index] = '';
            };
        });
        return sums;
    }

    const downloadFinanceRecords = ()=>{
      let currentStudentList = getFilteredFinanceRecords().map(x=>{ return { 
        ReferenceNo: String(x.id).padStart(8, "0"), 
        Date : moment(x.record_date).format('MM/DD/YYYY'),
        Type : x.record_type,
        Amount : x.amount,
        Method : x.payment_method,
        Category : x.payment_type,
        Student : x.student != null ? x.student.first_name + " " + x.student.last_name : '',
        Memo : x.memo,
        CreatedBy : x.user.name       
      };});
      let today = new Date();
      let fileName = 'Financial record List '+ today.toLocaleString();
      CommonService.exportToCsv(fileName, currentStudentList);
    }

    onMounted(async() => {
            await getStudents();
            await getTypes().then(async() =>{
                var date = new Date();
                var firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDayOfThisMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                const defaultFilter : FinanceFilter = {
                    record_types : ['Expense', 'Deposit'],
                    record_dates : [firstDayOfThisMonth, lastDayOfThisMonth],
                    payment_methods : Object.values(paymentMethods.values)
                }
                setFinanceFilter(defaultFilter);

                await getFinanceRecords();
                if(route.params.param==='create'){
                    let recordCreateButton = document.getElementById('record_create_button');
                    recordCreateButton?.click();
                }
            });
        setCurrentPageBreadcrumbs("Financial", []);      
        MenuComponent.reinitialization();  
    });

    return {
      financeRecords,
      financeFilter,
      pagedTransactions,
      pageSize,
      page,
      search,
      transactionTypes,
      paymentMethods,
      paymentTypes,
      setPage,
      handleDelete,
      getSummaries,
      getFinanceRecords,
      moment,
      students,
      getFilteredFinanceRecords,
      downloadFinanceRecords,
      handleSizeChange,
      pageSizes
    };
  },
});
</script>

<style scoped>
* >>> .delete_icon {
    opacity: 0.3;
}

* >>> .delete_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}

* >>> .financial-filter.show{
  z-index: 85 !important;
}

*>>> .el-table{
    color:#181C32;
}

*>>> .el-table td{
  padding: 6px 0;
}
</style>
