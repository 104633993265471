
import { defineComponent, onMounted, reactive, computed, ref, onBeforeMount } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import FinancialFilter from "@/views/financial/FinancialFilter.vue";
import CreateRecord from "@/views/financial/modals/CreateRecord.vue";
import { FinanceFilter } from "@/types/FinanceModel";
import { setFinanceFilter } from "@/core/helpers/finance";
import { useStore } from "vuex";
import { ElMessageBox } from "element-plus";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import { useRoute } from "vue-router";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import CommonService from "@/core/services/CommonService";

export default defineComponent({
  name: "financial",
  components: {
    FinancialFilter,
    CreateRecord
  },
  setup() {
    const store = useStore();
    const transactionTypes = [];
    const paymentMethods = [];
    const paymentTypes = [];
    const students = [];
    const route= useRoute();
    
    const getTypes = async() => {
        ApiService.setHeader();
        await ApiService.get("finance/types")
                .then(({ data }) => {
                    transactionTypes.values = data.recordTypes;
                    paymentMethods.values = data.paymentMethods;
                    paymentTypes.values = data.paymentTypes;
                })
                .catch(({ response }) => {
                console.log('getTypes error => '+response.data.errors);
                });
    }

    const getStudents = async() =>{ 
        ApiService.setHeader();
        await ApiService.get("student/status/Active,Draft,COS,Initial,Reinstate,Exit")
                .then(({ data }) => {
                    students.values = data.data;
                })
                .catch(({ response }) => {
                console.log('getStudents error => '+response.data.errors);
                });
    }

    const financeRecords : any[] = reactive([]);

    const getFinanceRecords = async () => {
        if(financeRecords.length > 0){
            financeRecords.splice(0, financeRecords.length);
        }

        await new Promise<void>((resolve, reject) => {   
            ApiService.setHeader();         
            ApiService.post("finance/search-records", financeFilter.value)
                .then(({ data }) => {
                    Object.assign(financeRecords,data.data);
                    resolve();
                })
                .catch(({ response }) => {
                    reject(new Error ('getFinanceRecords error => '+response.data.errors));
                });
        });
    }
    
    const pageSize = ref(20);
    let page = reactive({pageValue : 1});
    const pageSizes = computed(()=>{
      let sizes = [20];
      let curr_length = getFilteredFinanceRecords().length;
      if(curr_length > 20){
        sizes.push(50);
        if(curr_length >50){
          let i = 0;
          while(i<curr_length){
            i+=100;
            sizes.push(i);
          }
        }
      }

      return sizes;
    })
    
    const handleSizeChange = (val: number) => {
      pageSize.value = val; 
    }

    const financeFilter = computed(() => {
        return  store.getters.currentFinanceFilter.financeFilter;
    });

    const getFilteredFinanceRecords = () =>{
        return financeRecords
                .filter(data => (!search 
                            || String(data.id).padStart(8, "0").toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.payment_method.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.amount.toString().includes(search.searchValue.toLowerCase())
                            || data.memo.toLowerCase().includes(search.searchValue.toLowerCase())
                            || data.record_type.toLowerCase().includes(search.searchValue.toLowerCase())
                            || (data.student?.first_name + " " + data.student?.last_name).toLowerCase().includes(search.searchValue.toLowerCase()))
                )
                .sort(function(a,b) { 
                    if(a.id == b.id) return 0;

                    if (Date.parse(a.record_date!) < Date.parse(b.record_date!))
                        return 1;
                    if (Date.parse(a.record_date!) > Date.parse(b.record_date!))
                        return -1;
                    if(a.id < b.id) 
                        return  1;
                    if(a.id > b.id)
                        return -1;

                    return 0;
                });
    }

    const pagedTransactions = computed(()=>{
      return getFilteredFinanceRecords()
                .slice(pageSize.value * page.pageValue - pageSize.value, pageSize.value * page.pageValue);
    })

    const setPage = (val) => {
      page.pageValue = val;
    }

    const handleDelete = (row) => {
        ElMessageBox.confirm('Are you sure to delete this record?')
        .then(() => {
            ApiService.setHeader();
            ApiService.delete("finance/record/"+row.id)
                .then(() => {
                    let index = financeRecords.findIndex(d => d.id === row.id);
                    financeRecords.splice(index,1);   
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        });
    };

    const search = reactive({searchValue : ''});

    const getSummaries = (param) => {
        const { columns, data } = param;
        const sums = Array<any>();
        columns.forEach((column, index) => {
            if (index === 0) {
                sums[index] = 'Total';
                return;
            }
            else if(index ===3) {
                var totalAmount = 0;
                if (financeRecords) {
                    getFilteredFinanceRecords()
                            .forEach(record => {
                                let value = parseFloat(record.amount);                                
                                if(record.record_type == 'Deposit' || record.record_type == 'Credit' || record.record_type == 'Payable'){
                                    totalAmount = totalAmount + value;
                                }
                                else{
                                    totalAmount = totalAmount - value;
                                }
                            });

                    sums[index] = '$'+totalAmount;
                    return;
                } 
                sums[index] = '$ '+totalAmount;
            }
            else{
                sums[index] = '';
            };
        });
        return sums;
    }

    const downloadFinanceRecords = ()=>{
      let currentStudentList = getFilteredFinanceRecords().map(x=>{ return { 
        ReferenceNo: String(x.id).padStart(8, "0"), 
        Date : moment(x.record_date).format('MM/DD/YYYY'),
        Type : x.record_type,
        Amount : x.amount,
        Method : x.payment_method,
        Category : x.payment_type,
        Student : x.student != null ? x.student.first_name + " " + x.student.last_name : '',
        Memo : x.memo,
        CreatedBy : x.user.name       
      };});
      let today = new Date();
      let fileName = 'Financial record List '+ today.toLocaleString();
      CommonService.exportToCsv(fileName, currentStudentList);
    }

    onMounted(async() => {
            await getStudents();
            await getTypes().then(async() =>{
                var date = new Date();
                var firstDayOfThisMonth = new Date(date.getFullYear(), date.getMonth(), 1);
                var lastDayOfThisMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                const defaultFilter : FinanceFilter = {
                    record_types : ['Expense', 'Deposit'],
                    record_dates : [firstDayOfThisMonth, lastDayOfThisMonth],
                    payment_methods : Object.values(paymentMethods.values)
                }
                setFinanceFilter(defaultFilter);

                await getFinanceRecords();
                if(route.params.param==='create'){
                    let recordCreateButton = document.getElementById('record_create_button');
                    recordCreateButton?.click();
                }
            });
        setCurrentPageBreadcrumbs("Financial", []);      
        MenuComponent.reinitialization();  
    });

    return {
      financeRecords,
      financeFilter,
      pagedTransactions,
      pageSize,
      page,
      search,
      transactionTypes,
      paymentMethods,
      paymentTypes,
      setPage,
      handleDelete,
      getSummaries,
      getFinanceRecords,
      moment,
      students,
      getFilteredFinanceRecords,
      downloadFinanceRecords,
      handleSizeChange,
      pageSizes
    };
  },
});
